// This is a module using an object literal pattern.
// It's an easy way to organize your custom JavaScript into modules with methods.

import * as core from '../core';
import loadGoogleMapsAPI from 'load-google-maps-api'; // google maps api loader
import Opts from './gmap_opts';
// Here's jQuery in case you need it. If you're just doing DOM manipulation, you
// probably won't need it. Recommend using core.dom module to handle node caching.
// import $ from 'jquery/dist/jquery';

let $jsElements = null;

/**
 *
 * @public
 * @module Gmap
 * @description A Google Maps Api hook module.
 *
 */
const Gmap = {
  /**
   *
   * @public
   * @method init
   * @memberof Gmap
   * @description Method runs once when window loads.
   *
   */
  init () {
    if (this.isActive()) {
      initElement();
    }
    // console.log( "Gmap module: initialized" );
  },

  /**
   *
   * @public
   * @method isActive
   * @memberof Gmap
   * @description Method informs of active status.
   * @returns {boolean}
   *
   */
  isActive () {
    return (this.getElements() > 0);
  },

  /**
   *
   * @public
   * @method getElements
   * @memberof Gmap
   * @description Method queries DOM for this modules node.
   * @returns {number}
   *
   */
  getElements () {
    $jsElements = core.dom.body.find('#map');

    return ($jsElements.length);
  }
};

/**
 *
 * @private
 * @method execElement
 * @memberof Gmap
 * @description Handles execution of something.
 * @param {jQuery} $element The element.
 *
 */
const execElement = function ($element) {
  // cache the map element as a pure js variable
  let mapEl = $element[0];
  // object literal that holds options for google maps api loader
  let opts = {
    key: 'AIzaSyAADAGUm7WDkR14v70VqyKJBvUxHJFcbJ0',
    libraries: ['places'] // includes places libraries
  };

  // load the google maps api then execute function on success or fail
  loadGoogleMapsAPI(opts).then((googleMaps) => {
    // On succesful load, do this:

    // store the window google object for local use
    const google = window.google;

    // create the map
    let _map = new google.maps.Map(mapEl, {
      center: Opts.center,
      zoom: Opts.zoom(),
      styles: Opts.styles,
      backgroundColor: 'none',
      draggable: false,
      scrollwheel: false,
      mapTypeControl: false,
      streetViewControl: false
    });

    // utility to center map on resize
    // see: http://stackoverflow.com/questions/8792676/center-google-maps-v3-on-browser-resize-responsive
    google.maps.event.addDomListener(window, 'resize', function () {
      _map.panTo(Opts.center); // fetch the center coordinates
      _map.setZoom(Opts.zoom()); // fetch a new zoom value
    });

    // utility to enable scrolling when user interacts with map
    let timer; // holds setTimeout function
    let self; // reference to this in mouseover event listener
    google.maps.event.addListener(_map, 'mousedown', function () {
      this.setOptions({
        draggable: true,
        scrollwheel: true
      });
    });
    google.maps.event.addListener(_map, 'mouseover', function () {
      self = this;
      timer = setTimeout(function () {
        self.setOptions({
          draggable: true,
          scrollwheel: true
        });
      }, 1000);
    });
    google.maps.event.addListener(_map, 'mouseout', function () {
      this.setOptions({
        draggable: false,
        scrollwheel: false
      });
      clearTimeout(timer);
    });

    // create the map markers and their info windows
    function markers (data) {
      // the path to the icon pin image
      let iconImage = '/assets/img/icon_pin.png';

      // the array to hold all the pin variables
      let _pins = [];

      // the array to hold all the infowindow variables
      let _infoWindows = [];

      // loop through each location key and make a map pin, and info window
      for (let key in data.locations) {
        // get this key's location data
        let location = data.locations[key];
        // format this key's address query
        let encodedAddress = encodeURIComponent(location.addressLine1) + encodeURIComponent(location.addressLine2);
        // format this key's info window content
        let content = '<a target="_blank" href="https://maps.google.com/?q=' + encodedAddress + '">' +
          location.addressLine1 + '<br/>' +
          location.addressLine2 + '</a>';

        // make a map pin for this key
        _pins[key] = new google.maps.Marker({
          position: location.cords,
          map: _map,
          icon: iconImage
        });

        // info window that contains address string
        _infoWindows[key] = new google.maps.InfoWindow({
          content: content
        });

        // event listener that opens info window
        _pins[key].addListener('click', function () {
          _infoWindows[key].open(_map, _pins[key]);
        });

        // Misc:
        // console.log('Here\'s a location key name');
        // console.log(key); // log this key's name
        // console.log('Here\'s a location key\'s location data');
        // console.log(location); // log this key's location data
        // console.log('Here\'s a pin from the current loop');
        // console.log(_pins[key]); // log this map pin
        // console.log('Here\'s an info window from the current loop');
        // console.log(_infoWindows[key]); // log this info window
      }
    }

    // create the map markers according to the Opts object
    markers(Opts);

    // Misc:
    console.log('Google Maps API in use');
    console.log('Here\'s the googlemaps object:');
    console.log(googleMaps); // => Object { Animation: Object, .. }
    // console.log('Here\'s the element that contains the map:');
    // console.log(mapEl); // => <div id="map" class="is-initialized"></div>
  }).catch((err) => {
    // On failed load, do this:
    console.log('Google Maps API failed to load');
    console.error(err); // Log the error
    // remove initialized class from hooked element
    $element.removeClass('is-initialized');
  });

  // Misc:
  // console.log(`Look ma, there's an element, and its data attributes!`);
  // console.log($element);
};

/**
 *
 * @private
 * @method initElement
 * @memberof Gmap
 * @description This module would do something with your elements.
 *
 */
const initElement = function () {
  const $notLoaded = $jsElements.not('.is-initialized');
  let $element = null;
  let i = $notLoaded.length;

  for (i; i--;) {
    $element = $jsElements.eq(i);

    $element.addClass('is-initialized');

    execElement($element);
  }
};

/******************************************************************************
 * Export
*******************************************************************************/
export default Gmap;
