// This is a module using an object literal pattern.
// It's an easy way to organize your custom JavaScript into modules with methods.

import * as core from '../core';
// Here's jQuery in case you need it. If you're just doing DOM manipulation, you
// probably won't need it. Recommend using core.dom module to handle node caching.
import $ from 'jquery/dist/jquery';
import jQuery from 'jquery/dist/jquery'; // duplicate needed for use of jQuery variable in parallax plugin
// export jquery for plugin use
window.$ = $;
window.jQuery = jQuery;
// parallax.js plugin
require('parallax');

let $jsElements = null;

/**
 *
 * @public
 * @module Parallax
 * @description An Parallax hook module.
 *
 */
const Parallax = {
  /**
   *
   * @public
   * @method init
   * @memberof Parallax
   * @description Method runs once when window loads.
   *
   */
  init () {
    if (this.isActive()) {
      initElement();
    }
    // console.log( "Parallax module: initialized" );
  },

  /**
   *
   * @public
   * @method isActive
   * @memberof Parallax
   * @description Method informs of active status.
   * @returns {boolean}
   *
   */
  isActive () {
    return (this.getElements() > 0);
  },

  /**
   *
   * @public
   * @method getElements
   * @memberof Parallax
   * @description Method queries DOM for this modules node.
   * @returns {number}
   *
   */
  getElements () {
    $jsElements = core.dom.body.find('.parallax-window');

    return ($jsElements.length);
  }
};

/**
 *
 * @private
 * @method execElement
 * @memberof Parallax
 * @description Handles execution of something.
 * @param {jQuery} $element The element.
 *
 */
const execElement = function ($element) {
  // Grab some data from $el.
  // const elementData = $element.data();

  // function that returns the image data-src url
  function getSrc ($el) {
    let source = $el.data('image') + '?format=1500w'; // gets 1500 wide image
    return source;
  }

  // function that returns the focal point coordinates
  function getPosition ($el) {
    // variables where positions will be stashed
    let xPos;
    let yPos;
    // array to stash all possible positions
    let positions = [
      'left',
      'right',
      'top',
      'bottom',
      'center'
    ];
    // get the focal point data from the element
    let imgFocalPoint = $el.data('image-focal-point').split(','); // an array of values seperated at the comma
    let x = imgFocalPoint[0]; // the x position
    let y = imgFocalPoint[1]; // the y position

    // conditional that sets the value of x
    if (x >= 0 && x <= 0.25) {
      xPos = positions[0]; // left
    } else if (x > 0.25 && x < 0.75) {
      xPos = positions[4]; // center
    } else {
      xPos = positions[1]; // right
    }

    // conditional that sets the value of y
    if (y >= 0 && y <= 0.25) {
      yPos = positions[2]; // top
    } else if (y > 0.25 && y < 0.75) {
      yPos = positions[4]; // center
    } else {
      yPos = positions[3]; // bottom
    }

    return xPos + ' ' + yPos;
  }

  // function that starts the parallax effect
  function startParallax ($el, callback) {
    $el.parallax({ // manually calls parallax plugin on the element
      imageSrc: getSrc($el), // returns the img url from the element
      position: getPosition($el)// returns the x and y focal points
    });
    callback(); // callback function
  }

  // function to add 'is-initialized' class to parallax elements
  // and 'parallax-enabled' class to body
  function isInit () {
    // if parallax-mirror elements exist
    if (core.dom.body.find('.parallax-mirror').length > 0) {
      // adds parallax-enabled class
      core.dom.body.addClass('parallax-enabled');
      // adds event listener to fade in parallax images on load
      core.dom.body.find('.parallax-mirror img').on('load', function () {
        $(this).parent('.parallax-mirror').addClass('is-initialized');
      });
    }
  }

  // call the functions that create the parallax effect
  startParallax($element, isInit);

  // Misc:
  // console.log(`Look ma, there's an element, and its data attributes!`);
  // console.log($element);
  // console.log(elementData);
};

/**
 *
 * @private
 * @method initElement
 * @memberof Parallax
 * @description This module would do something with your elements.
 *
 */
const initElement = function () {
  const $notLoaded = $jsElements.not('.is-initialized');
  let $element = null;
  let i = $notLoaded.length;

  for (i; i--;) {
    $element = $jsElements.eq(i);

    $element.addClass('is-initialized');

    execElement($element);
  }
};

/******************************************************************************
 * Export
*******************************************************************************/
export default Parallax;
