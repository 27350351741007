import * as core from '../core';

/**
 *
 * @public
 * @module Opts
 * @description Holds options for a google map
 *
 */
const Opts = {
  /**
   *
   * @public
   * @member center
   * @memberof Opts
   * @description Lat & Lng of map center.
   *
   */
  center: {
    lat: 34.403764,
    lng: -83.564938
  },

  /**
   *
   * @public
   * @member zoom
   * @memberof Opts
   * @description Zoom value.
   *
   */
  zoom: function () {
    if (core.dom.win.width() < 500) { // if window width is less than 500px
      return 4; // return a zoom value of 7
    } else {
      return 5; // return a zoom value of 8
    }
  },

  /**
   *
   * @public
   * @member locations
   * @memberof Opts
   * @description Location data. Addresses and lat+lng coordinates
   *
   */
  locations: {
    tampa: {
      addressLine1: '7211 N. Dale Mabry Hwy., Suite 203',
      addressLine2: 'Tampa, Florida 33614',
      cords: {
        lat: 28.0154817,
        lng: -82.5065303
      }
    },
    lakeland: {
      addressLine1: '231 S. Florida Ave.',
      addressLine2: 'Lakeland, Florida 33801',
      cords: {
        lat: 28.0417994,
        lng: -81.9574261
      }
    },
    orlando: {
      addressLine1: '8865 Commodity Circle, Suite 13, Unit 101',
      addressLine2: 'Orlando, Florida 32819',
      cords: {
        lat: 28.442358,
        lng: -81.4306457
      }
    },
    sarasota: {
      addressLine1: '4123 N. Tamiami Trail, Unit 203',
      addressLine2: 'Sarasota, FL 34234',
      cords: {
        lat: 27.370312,
        lng: -82.553060
      }
    },
    cincinnati: {
      addressLine1: '10945 Reed Hartman Hwy., Suite 311/313',
      addressLine2: 'Cincinnati, OH 45242',
      cords: {
        lat: 39.265715,
        lng: -84.382280
      }
    }
  },

  /**
   *
   * @public
   * @member styles
   * @memberof Opts
   * @description The custom styles for a google map.
   *
   */
  styles: [
    {
      'featureType': 'all',
      'elementType': 'all',
      'stylers': [
        {
          'hue': '#0089ff'
        }, {
          'saturation': '100'
        }
      ]
    }, {
      'featureType': 'road',
      'elementType': 'geometry',
      'stylers': [
        {
          'lightness': '-10'
        }, {
          'visibility': 'simplified'
        }
      ]
    }, {
      'featureType': 'road',
      'elementType': 'labels',
      'stylers': [
        {
          'visibility': 'on'
        }
      ]
    }
  ]
};

/******************************************************************************
 * Export
*******************************************************************************/
export default Opts;
