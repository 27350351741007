// This is a module using an object literal pattern.
// It's an easy way to organize your custom JavaScript into modules with methods.

import * as core from '../core';
// Here's jQuery in case you need it. If you're just doing DOM manipulation, you
// probably won't need it. Recommend using core.dom module to handle node caching.
// import $ from 'jquery/dist/jquery';

let $jsElements = null;

/**
 *
 * @public
 * @module Loadimgs
 * @description A module that handles image loading.
 *
 */
const Loadimgs = {
  /**
   *
   * @public
   * @method init
   * @memberof Loadimgs
   * @description Method runs once when window loads.
   *
   */
  init () {
    if (this.isActive()) {
      initElement();
    }
    // console.log( "Loadimgs module: initialized" );
  },

  /**
   *
   * @public
   * @method isActive
   * @memberof Loadimgs
   * @description Method informs of active status.
   * @returns {boolean}
   *
   */
  isActive () {
    return (this.getElements() > 0);
  },

  /**
   *
   * @public
   * @method getElements
   * @memberof Loadimgs
   * @description Method queries DOM for this modules node.
   * @returns {number}
   *
   */
  getElements () {
    $jsElements = core.dom.body.find('img[data-src]');

    return ($jsElements.length);
  }
};

/**
 *
 * @private
 * @method execElement
 * @memberof Loadimgs
 * @description Handles execution of something.
 * @param {jQuery} $element The element.
 *
 */
const execElement = function ($element) {
  // Get the global ImageLoader function
  const ImageLoader = window.ImageLoader;

  // Get all images
  let images = $element.toArray();

  // function to fade in an image
  function fadeImg (img) {
    img.style.opacity = 1;
  }

  // function to callback to later and load images
  function loadImgs () {
    for (var i = 0; i < images.length; i++) {
      ImageLoader.load(images[i], {
        load: true
      });
      if (images[i].complete) {
        fadeImg(images[i]);
      } else {
        images[i].addEventListener('load', fadeImg(images[i]));
      }
    }
  }

  // The event subscription that loads images when the page is ready
  document.addEventListener('DOMContentLoaded', loadImgs);
  // The event subscription that reloads images on resize
  window.addEventListener('resize', loadImgs);

  // Misc:
  // console.log(`Look ma, there's an img!`);
  // console.log(images);
};

/**
 *
 * @private
 * @method initElement
 * @memberof Loadimgs
 * @description This module would do something with your elements.
 *
 */
const initElement = function () {
  const $notLoaded = $jsElements.not('.is-initialized');
  let $element = null;
  let i = $notLoaded.length;

  for (i; i--;) {
    $element = $jsElements.eq(i);

    $element.addClass('is-initialized');

    execElement($element);
  }
};

/******************************************************************************
 * Export
*******************************************************************************/
export default Loadimgs;
