/**
 * This is the entrypoint to the JS in your app.
 * ES6 features are supported inside this file.
 */

import * as core from './core';
import Flex from './modules/flex';
import Loadimgs from './modules/imgloader';
import MobileNav from './modules/mobile-nav';
import Parallax from './modules/parallax';
import Accordion from './modules/accordion';
import Gmap from './modules/gmap';
import ScrollToTop from './modules/scrolltotop';

/**
 *
 * @public
 * @class App
 * @classdesc Load the App application Class to handle everything.
 *
 */
class App {
  constructor () {
    this.core = core;
    this.Flex = Flex;
    this.Loadimgs = Loadimgs;
    this.MobileNav = MobileNav;
    this.Parallax = Parallax;
    this.Accordion = Accordion;
    this.Gmap = Gmap;
    this.ScrollToTop = ScrollToTop;
    // starts modules
    this.initModules();
  }

  /**
   *
   * @public
   * @instance
   * @method initModules
   * @memberof App
   * @description Initialize application modules.
   *
   */
  initModules () {
    this.Flex.init(this); // flexbox polyfill
    this.Loadimgs.init(this); // start image loader
    this.MobileNav.init(this); // Mobile Nav Scripts
    this.Parallax.init(this); // Parallax Scripts
    this.Accordion.init(this); // Accordion Scripts
    this.Gmap.init(this); // Google Map Api Scripts
    this.ScrollToTop.init(this); // Scroll To Top Button Scripts
  }
}

/******************************************************************************
 * Expose
*******************************************************************************/
window.app = new App();

/******************************************************************************
 * Export
*******************************************************************************/
export default window.app;
