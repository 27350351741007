// This is a module using an object literal pattern.
// It's an easy way to organize your custom JavaScript into modules with methods.

import * as core from '../core';
// Here's jQuery in case you need it. If you're just doing DOM manipulation, you
// probably won't need it. Recommend using core.dom module to handle node caching.
// import $ from 'jquery/dist/jquery';
const flexibility = require('flexibility'); // require flexibility polyfill
let $jsElements = null;

/**
 *
 * @public
 * @module Flex
 * @description An Flex hook module.
 *
 */
const Flex = {
  /**
   *
   * @public
   * @method init
   * @memberof Flex
   * @description Method runs once when window loads.
   *
   */
  init () {
    if (this.isActive()) {
      initElement();
    }
    // console.log( "Flex module: initialized" );
  },

  /**
   *
   * @public
   * @method isActive
   * @memberof Flex
   * @description Method informs of active status.
   * @returns {boolean}
   *
   */
  isActive () {
    return (this.getElements() > 0);
  },

  /**
   *
   * @public
   * @method unload
   * @memberof Flex
   * @description Method performs unloading actions for this module.
   *
   */
  unload () {
    // Typically unloading and tearing down isn't required unless you're
    // using a complete AJAX Squarespace website that functions like
    // a single page application.
    this.teardown();
  },

  /**
   *
   * @public
   * @method teardown
   * @memberof Flex
   * @description Method performs cleanup after this module. Removes events, null vars etc...
   *
   */
  teardown () {
    $jsElements = null;
    core.dom.html.removeClass('is-initialized');
  },

  /**
   *
   * @public
   * @method getElements
   * @memberof Flex
   * @description Method queries DOM for this modules node.
   * @returns {number}
   *
   */
  getElements () {
    // function to detect flexbox and return an element if no flexbox
    $jsElements = core.dom.html;

    return ($jsElements.length);
  }
};

/**
 *
 * @private
 * @method execElement
 * @memberof Flex
 * @description Handles execution of something.
 * @param {jQuery} $element The element.
 *
 */
const execElement = function ($element) {
  // cache Modernizr object
  const Modernizr = window.Modernizr;

  // timeout variable
  let onresizeTimeout;

  // function to recalculate flexbox on window resize
  function onresize () {
    window.onresize = null;

    if (!onresizeTimeout) {
      onresizeTimeout = setTimeout(function () {
        onresizeTimeout = null;

        flexibility(document.documentElement);

        window.onresize = onresize;
      }, 1000 / 60);
    }
  }

  // conditional that determines whether flexibility will run
  if (Modernizr.flexbox) { // set to Modernizr.flexbox
    // Unload
    Flex.unload();

    // Modern Flexbox with `flex-wrap` is supported
    console.log('Flexbox is supported');
  } else {
    // globally available onresize variable
    window.onresize = onresize;

    // call flexibility on documentElement when document is ready
    flexibility(document.documentElement);// call flexibility on documentElement

    // Misc:
    console.log('Flexbox is not supported. Flexibility polyfill in use on:');
    console.log($element);
  }

  // Misc:
  // console.log(`Look ma, there's an element, and its data attributes!`);
  // console.log($element);
};

/**
 *
 * @private
 * @method initElement
 * @memberof Flex
 * @description This module would do something with your elements.
 *
 */
const initElement = function () {
  const $notLoaded = $jsElements.not('.is-initialized');
  let $element = null;
  let i = $notLoaded.length;

  for (i; i--;) {
    $element = $jsElements.eq(i);

    $element.addClass('is-initialized');

    execElement($element);
  }
};

/******************************************************************************
 * Export
*******************************************************************************/
export default Flex;
