// This is a module using an object literal pattern.
// It's an easy way to organize your custom JavaScript into modules with methods.

import * as core from '../core';
// Here's jQuery in case you need it. If you're just doing DOM manipulation, you
// probably won't need it. Recommend using core.dom module to handle node caching.
// import $ from 'jquery/dist/jquery';

let $jsElements = null;

/**
 *
 * @public
 * @module ScrollToTop
 * @description An ScrollToTop hook module.
 *
 */
const ScrollToTop = {
  /**
   *
   * @public
   * @method init
   * @memberof ScrollToTop
   * @description Method runs once when window loads.
   *
   */
  init () {
    if (this.isActive()) {
      initElement();
    }
    // console.log( "ScrollToTop module: initialized" );
  },

  /**
   *
   * @public
   * @method isActive
   * @memberof ScrollToTop
   * @description Method informs of active status.
   * @returns {boolean}
   *
   */
  isActive () {
    return (this.getElements() > 0);
  },

  /**
   *
   * @public
   * @method getElements
   * @memberof ScrollToTop
   * @description Method queries DOM for this modules node.
   * @returns {number}
   *
   */
  getElements () {
    $jsElements = core.dom.body.find('#scroll-to-top');

    return ($jsElements.length);
  }
};

/**
 *
 * @private
 * @method execElement
 * @memberof ScrollToTop
 * @description Handles execution of something.
 * @param {jQuery} $element The element.
 *
 */
const execElement = function ($element) {
  // the function that scrolls window to top
  function scrollToTop () {
    core.dom.doc.find('body,html').animate({
      scrollTop: 0
    }, 500);
  }

  // a debounced function that will be bound to event subscriptions
  let checkScroll = core.util.debounce(function () {
    // if user is scrolled past the header
    if (core.dom.win.scrollTop() > core.dom.body.find('header').height()) {
      // show the button
      $element.css({
        visibility: 'visible',
        opacity: 1
      });
      // Misc:
      // console.log('You\'ve scrolled past the header!');
    } else {
      // hide button
      $element.css({
        opacity: 0
      }).animate({
        visibility: 'hidden'
      }, 800);
    }
    // if user is scrolled to the bottom of the page
    if (core.dom.win.scrollTop() + core.dom.win.height() === core.dom.doc.height()) {
      $element.animate({
        bottom: core.dom.body.find('footer').height() // displace the element bottom by footer's height
      }, 200);
      // Misc:
      // console.log('You\'re at the bottom of the page!');
    } else {
      $element.animate({
        bottom: 0 // reset the bottom value
      }, 200);
    }
  }, 400); // debounce at 400ms
  // Event Subscriptions
  // bind the checkScroll to window scroll event
  core.dom.win.on('scroll', checkScroll);
  // bind the checkScroll function on document ready
  core.dom.doc.ready(function () {
    checkScroll();
  });
  // bind the scroll to top function when button is clicked
  $element.on('click', function (e) {
    e.preventDefault();
    scrollToTop();
  });
  // Misc:
  // console.log(`Look ma, there's an element!`);
  // console.log($element);
};

/**
 *
 * @private
 * @method initElement
 * @memberof ScrollToTop
 * @description This module would do something with your elements.
 *
 */
const initElement = function () {
  const $notLoaded = $jsElements.not('.is-initialized');
  let $element = null;
  let i = $notLoaded.length;

  for (i; i--;) {
    $element = $jsElements.eq(i);

    $element.addClass('is-initialized');

    execElement($element);
  }
};

/******************************************************************************
 * Export
*******************************************************************************/
export default ScrollToTop;
