/**
 *
 * @public
 * @namespace core
 * @description Holds the different core modules.
 *
 */
import dom from './dom';
import util from './util';
export {
  dom,
  util
};
