// This is a module using an object literal pattern.
// It's an easy way to organize your custom JavaScript into modules with methods.

import * as core from '../core';
// Here's jQuery in case you need it. If you're just doing DOM manipulation, you
// probably won't need it. Recommend using core.dom module to handle node caching.
import $ from 'jquery/dist/jquery';

let $jsElements = null;

/**
 *
 * @public
 * @module Accordion
 * @description An Accordion hook module.
 *
 */
const Accordion = {
  /**
   *
   * @public
   * @method init
   * @memberof Accordion
   * @description Method runs once when window loads.
   *
   */
  init () {
    if (this.isActive()) {
      initElement();
    }
    // console.log( "Accordion module: initialized" );
  },

  /**
   *
   * @public
   * @method isActive
   * @memberof Accordion
   * @description Method informs of active status.
   * @returns {boolean}
   *
   */
  isActive () {
    return (this.getElements() > 0);
  },

  /**
   *
   * @public
   * @method unload
   * @memberof Accordion
   * @description Method performs unloading actions for this module.
   *
   */
  unload () {
    // Typically unloading and tearing down isn't required unless you're
    // using a complete AJAX Squarespace website that functions like
    // a single page application.
    this.teardown();
  },

  /**
   *
   * @public
   * @method teardown
   * @memberof Accordion
   * @description Method performs cleanup after this module. Removes events, null vars etc...
   *
   */
  teardown () {
    $jsElements = null;
  },

  /**
   *
   * @public
   * @method getElements
   * @memberof Accordion
   * @description Method queries DOM for this modules node.
   * @returns {number}
   *
   */
  getElements () {
    $jsElements = core.dom.body.find('.accordion-content');

    return ($jsElements.length);
  }
};

/**
 *
 * @private
 * @method execElement
 * @memberof Accordion
 * @description Handles execution of something.
 * @param {jQuery} $element The element.
 *
 */
const execElement = function ($element) {
  /**
   * Scrolls to a section
   * @function scrollToSection
   */
  function scrollToSection (el) {
    core.dom.body.animate({
      scrollTop: el.offset().top
    }, 300);
    // console.log("scrolled to " + el.attr('href') + " section");
  }

  /**
   * Closes all accordion sections
   * @function closeAccordionSection
   */
  function closeAccordionSection () {
    // removes open states of all accordion titles
    $element.find('.accordion-section-title').removeClass('active flipButton');
    // removes open states of all accordion content
    $element.find('.accordion-section-content').slideUp(300, function () {
      // Fire a resize event that in turn fires any scripts listening for window resize/scroll
      core.dom.win.trigger('resize').trigger('scroll');
    }).removeClass('open');
  }

  /**
   * Opens an accordion section
   * @function openAccordionSection
   */
  function openAccordionSection (el) {
    // Grab current anchor value
    let currentAttrValue = el.attr('href');
    // Add active class to section title
    el.addClass('active');
    // Add flipButton class to section title
    el.addClass('flipButton');
    // Open up the hidden content panel
    el.siblings(currentAttrValue).slideDown(300, function () {
      // scroll to element
      scrollToSection(el);
      // Fire a resize event that in turn fires any scripts listening for window resize/scroll
      core.dom.win.trigger('resize').trigger('scroll');
    }).addClass('open');
  }

  /**
   * Bind the UI elements to event listeners
   */
  $element.find('.accordion-section-title').click(function (e) {
    // prevent link clicking
    e.preventDefault();

    // get this element
    let $thisEl = $(this);
    // the deferred closed string of functions
    let closed = $.Deferred();
    closed.done(closeAccordionSection, scrollToSection);
    // the deferred opened string of functions
    let opened = $.Deferred();
    opened.done(closeAccordionSection, openAccordionSection);

    if ($(e.target).is('.active')) {
      // close all accordions then scroll to the clicked element
      closed.resolve($thisEl);
    } else {
      // close all accordions then open $thisEl accordion section
      opened.resolve($thisEl);
    }
  });
  // Misc:
  // console.log(`Look ma, there's an element, and its data attributes!`);
  // console.log($element);
};

/**
 *
 * @private
 * @method initElement
 * @memberof Accordion
 * @description This module would do something with your elements.
 *
 */
const initElement = function () {
  const $notLoaded = $jsElements.not('.is-initialized');
  let $element = null;
  let i = $notLoaded.length;

  for (i; i--;) {
    $element = $jsElements.eq(i);

    $element.addClass('is-initialized');

    execElement($element);
  }
};

/******************************************************************************
 * Export
*******************************************************************************/
export default Accordion;
