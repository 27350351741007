// This is a module using an object literal pattern.
// It's an easy way to organize your custom JavaScript into modules with methods.

import * as core from '../core';
// Here's jQuery in case you need it. If you're just doing DOM manipulation, you
// probably won't need it. Recommend using core.dom module to handle node caching.
import $ from 'jquery/dist/jquery';

let $jsElements = null;

/**
 *
 * @public
 * @module MobileNav
 * @description A module that starts the mobile navigation scripts.
 *
 */
const MobileNav = {
  /**
   *
   * @public
   * @method init
   * @memberof MobileNav
   * @description Method runs once when window loads.
   *
   */
  init () {
    if (this.isActive()) {
      initElement();
    }
    // console.log( "MobileNav module: initialized" );
  },

  /**
   *
   * @public
   * @method isActive
   * @memberof MobileNav
   * @description Method informs of active status.
   * @returns {boolean}
   *
   */
  isActive () {
    return (this.getElements() > 0);
  },

  /**
   *
   * @public
   * @method getElements
   * @memberof MobileNav
   * @description Method queries DOM for this modules node.
   * @returns {number}
   *
   */
  getElements () {
    $jsElements = core.dom.body.find('.mobile-nav-wrapper, #menu-trigger');

    return ($jsElements.length);
  }
};

/**
 *
 * @private
 * @method bindUI
 * @memberof MobileNav
 * @description Binds elements to UI functions.
 * @param {jQuery} $element The element.
 *
 */
const bindUI = function ($element) {
  const animationTime = 180;
  let $mobileNavWrapper = $element.filter('.mobile-nav-wrapper');
  let $trigger = $element.filter('#menu-trigger');

  // adds the mobile nav open class from body
  function navOpened () {
    core.dom.body.addClass('mobile-nav-open');
  }

  // removes the mobile nav open class from body
  function navClosed () {
    core.dom.body.removeClass('mobile-nav-open');
  }

  // adds event listenter to trigger resize when window scrolls
  // only active if the parallax effect is enabled
  function parallaxRefresh () {
    if (core.dom.body.hasClass('parallax-enabled')) {
      core.dom.win.trigger('resize').trigger('scroll');
    }
  }

  // slides in/out the mobile navigation
  // with the option for a callbak function
  function toggleNav (callback) {
    $mobileNavWrapper.slideToggle(animationTime, function (callback) {
      // refresh the parallax effect
      parallaxRefresh();
      // callback function
      callback;
    });
  }

  // Bind elements to UI
  // Toggle the mobile nav when the trigger is clicked
  $trigger.on('click', function () {
    // toggles the clicked state of the trigger
    $trigger.toggleClass('is-clicked');
    // toggles the mobile nav open state of the body content
    // class is added while navigation is toggled
    if (!core.dom.body.hasClass('mobile-nav-open')) {
      // if nav is closed, asynchronously show the nav and add the nav open class
      navOpened();
      toggleNav();
    } else if (core.dom.body.hasClass('mobile-nav-open')) {
      // if nav is open, syncronously hide nav, then remove nav open class
      toggleNav(navClosed());
    }
  });
  // toggle the dropdown menus when they are clicked
  $mobileNavWrapper.find('.has-dropdown').each(function () {
    $(this).on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass('dropdown-open').siblings('.dropdown').slideToggle(animationTime, function () {
        // refresh the parallax effect
        parallaxRefresh();
      });
    });
  });
  // Misc:
  // console.log('This is the mobile-nav-wrapper');
  // console.log($mobileNavWrapper);
  // console.log('This is the menu-trigger');
  // console.log($trigger);
};

/**
 *
 * @private
 * @method initElement
 * @memberof MobileNav
 * @description This module would do something with your elements.
 *
 */
const initElement = function () {
  const $notLoaded = $jsElements.not('.is-initialized');
  let $element = null;
  let i = $notLoaded.length;

  for (i; i--;) {
    $element = $jsElements.eq(i);

    $element.addClass('is-initialized');
  }

  bindUI($jsElements); // bind elements to ui functions
};

/******************************************************************************
 * Export
*******************************************************************************/
export default MobileNav;
